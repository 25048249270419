@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@200;400&display=swap');
body {
  background:#F5F5F5;
  font-family: 'Assistant', sans-serif;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
textarea {font-family: 'Assistant', sans-serif;}
.app {max-width: 834px;margin: 0 auto;}
header .menu {width: 31px;height:21px;margin: 27px;}
header .logo {margin-top: 31px;margin-left: 35px;width:88px;height:88px;}
header {
  display:flex;
  justify-content: space-between;
  background: white;
  border-radius: 10px;
  height: 75px;
  width: 100%;
}
ul.mainMenu { display: flex;padding:0;margin:15px 0 0 0;}
ul.mainMenu li { list-style: none; padding-left: 15px;}
ul.mainMenu li a { color: black;text-decoration: none;;}
ul.mainMenu li a:hover,
ul.mainMenu li.active a { color: #00ADB8;text-decoration: underline;}
.fileInput {padding-top: 15px;font-size:26px;}
main {text-align: right; direction:rtl;margin: 56px 45px 0 45px;}
main .boxnum.file,
main select,
main input { border: solid 1px #ccc; text-indent: 15px;}
main textarea {width: 100%;height:150px;background:white;border-radius: 10px;resize:none;padding:15px 15px 0 0;font-size:16px;border: solid 1px #ccc;}
.rcpMessageLastSentText {color: red;}
.controlpanel .items {margin: 0 auto; text-align: center;}
.controlpanel {display:block;}
.controlpanel .boxtxt,
.boxselect {
  margin: 25px 0; width:100%;height:60px;background:white;border-radius:10px;font-size:26px;
}
.controlpanel .boxnum {margin-bottom: 25px; width:100%;height:60px;background:white;border-radius:10px;font-size:32px;}
.controlpanel .boxnum.hide {display:none;}
.controlpanel .clean {color:#00ADB8;border:#00ADB8;height:47px;line-height:30px;width:102px;font-size:28px;border:1px solid #07B2CC;border-radius:30px;}
.controlpanel .send {background:#07B2CC;border:#00ADB8;height:47px;line-height:30px;width:149px;font-size:28px;border:1px solid #07B2CC;border-radius:30px;color:white;margin-right:10px;}
.controlpanel .send:disabled {opacity:0.3;}
.target {font-size:16px;margin-top:15px;line-height:35px;}
.tooltip {background-image:url('tooltip.svg');background-repeat: no-repeat;width:133px;height:39px;color:white;line-height:28px;text-align: center;}
.searchType {position:fixed;bottom:15px;left:15px;}
.searchType img {margin: 5px 30px 0 0;cursor:pointer;}
.clean {cursor: pointer;}